import React, { Component } from "react";

import "./App.css";
import { sleep } from "./sleep";
import { CardList } from "./components/card-list/card-list";
import { SearchBox } from "./components/search-box/search-box.component";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      monsters: [],
      searchField: ""
    };
  }

  // componentDidMount(){
  //   fetch('https://jsonplaceholder.typicode.com/users')
  //   .then( response => response.json())
  //   .then( users => this.setState({monster : users}));
  // }

  // async componentDidMount(){
  //   const resp = await fetch('https://jsonplaceholder.typicode.com/users')
  //   const monsters = await resp.json();
  //   this.setState({ monsters });
  // }

  componentDidMount() {
    this.setState({ loading: true }, async () => {
      await sleep(2000);
      const resp = await fetch("https://jsonplaceholder.typicode.com/users");
      const monsters = await resp.json();
      this.setState({ monsters, loading: false });
    });
  }

  render() {
    if (this.state.loading) {
      return <div>{"Loading..."}</div>;
    }
    const {monsters, searchField } = this.state;  //destructuring
    const filteredmonsters = monsters.filter(
      monster => monster.name.toLowerCase().includes(searchField.toLowerCase())
    );
    return (
      <div className="App">
        <h1>Monsters Rolodex</h1>
       <SearchBox 
        placeholder='search monsters'
        handleChange = {e => this.setState({ searchField: e.target.value })}
       />
        <CardList monsters={filteredmonsters} />
      </div>
    );
  }
}
export default App;
