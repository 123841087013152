import React from "react";
import "./card-list.styles.css";
import { Card } from "../card/card.component";

export const CardList = props => {
  return (
    <div className="card-list">
      {props.monsters.map(m => (
        // <h1 key={m.id}>{m.name}</h1>
        <Card key={m.id} monster={m}/>
      ))}
    </div>
  );
};
